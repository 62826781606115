/* Universal Styles */

html {
  font-size: 18px;
  width: 100%;
  height: 100%;
  background-color: #2F4F4f;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #000;
}

.image-container img {
  width: 100%;
}

/* App Styles */

.App {
  font-family: 'Work Sans', sans-serif;
}

h1 {
  padding: .66rem 0;
  text-align: center;
  background-color: #cca353;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #fff;
}