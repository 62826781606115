.Business {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 16.66rem;
    margin: 0 .5rem 2.3rem .5rem;
  }
  
  .Business .image-container {
    height: 16.66rem;
    margin-bottom: 1rem;
  }
  
  .Business h2 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .Business-information {
    display: flex;
    justify-content: space-between;
  }
  
  .Business-information p {
    font-size: .88rem;
    font-weight: 300;
    line-height: 1rem;
  }
  
  .Business-address {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .Business-reviews {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
  }
  
  .Business-reviews h3 {
    color: #CCA353;
    font-weight: 600;
  }
  
  .Business-reviews .rating {
    font-size: .88rem;
    line-height: 1rem;
  }